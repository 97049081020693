import React from "react"
import { Link } from "gatsby"
import Container from '@material-ui/core/Container'
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout noHero>
    <SEO title="404 - Sivua ei löydy" />
    <Container>
      <h1>404 - Sivua ei löydy</h1>
      <p>Etsimääsi sivua ei löytynyt sivustolta. Tarkista osoite, <Link to="/">palaa etusivulle</Link> tai <Link to="/haku">kokeile hakua</Link>.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
